.gallery-photo {
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: auto;
  z-index: 1;

  @include grid-column-margin;

  @include breakpoint(small only) {
    height: auto;
    width: 100%;
  }

  @include breakpoint(medium) {
    &::before,
    &::after {
      bottom: 12px;
      box-shadow: 0 15px 10px rgb(0 0 0 / 70%);
      content: "";
      height: 20%;
      left: 10px;
      max-width: 322px;
      position: absolute;
      transform: rotate(-1.5deg);
      width: 48%;
      z-index: -2;
    }

    &::after {
      left: auto;
      right: 10px;
      transform: rotate(1.5deg);
    }
  }

  img {
    margin-bottom: 0 !important;

    @include breakpoint(medium) {
      background-color: $black;
      border-radius: $global-radius;
      box-shadow: 0 0 3px rgb(0 0 0 / 40%);
      padding: 14px;
      position: relative;
    }
  }

  #left_arrow,
  #right_arrow {
    background-color: rgb(0 0 0 / 25%);
    display: none;
    height: 100%;
    position: absolute;
    width: 100px;
    z-index: 1;

    div {
      color: $white;
      display: block;
      font-size: rem-calc(24);
      margin-top: -12px;
      position: relative;
      text-align: center;
      text-decoration: none;
      top: 50%;
      width: 100%;
    }
  }

  #left_arrow {
    left: 0;
  }

  #right_arrow {
    right: 0;
  }

  &:hover #left_arrow,
  &:hover #right_arrow {
    display: block;
  }

  &__download {
    @include show-for(medium);
    @include grid-column-margin;

    ul {
      display: inline-block;
      list-style: none !important;
      margin: 0 0 0 5px !important;
    }

    li {
      display: inline-block;
      margin-bottom: 0 !important;

      & + li::before {
        content: "·";
        margin: 0 3px;
      }
    }
  }
}

@use "sass:math";

@include breakpoint(small only) {
  #collapse {
    & > *:not(.opened) {
      display: none;
    }

    //& > *:not(#collapse_button) {
    //  display: none;
    //}

    &_button {
      background-color: #2679ac;
      box-sizing: border-box;
      color: #f1f1f1;
      font-weight: $global-weight-normal;
      padding: 5px 15px;
      position: relative;
      text-decoration: none;

      &.expanded {
        &::after {
          transform: rotate(180deg);
          transform-origin: 50% 50%;
        }
      }

      &::after {
        @include css-triangle(6px, #bde6f5, down);

        display: none;
        margin-top: -1 * math.div(6px, 2);
        position: absolute;
        right: 1rem;
        top: 50%;
      }

      &.visible {
        &::after {
          display: block;
        }
      }
    }
  }
}

.mp-ads {
  border-top: 1px solid $white;
  margin: 0 !important;

  .columns {
    margin: 0;
    padding: 20px 15px;

    h2 {
      border-bottom: 2px solid #930;
      color: #930;
      font-size: rem-calc(18);
      line-height: 21px;
      margin-bottom: 16px;

      &.black {
        border-bottom: 2px solid #333;
        color: #333;
      }
    }
  }

  &-bg {
    background: $white url("bg-mp-ads.png") top right no-repeat;
  }

  .advert-list {
    padding: 0;
  }

  .button + .button {
    margin-left: 10px !important;
  }
}

.content {
  background-color: $white;

  @include breakpoint(small only) {
    padding-left: 10px;
    padding-right: 10px;
  }

  h2 {
    margin-bottom: 10px;

    @include small-h2;
  }

  &-header {
    background-color: $white;

    .inner {
      @include clearfix;

      margin: $global-margin 15px 0;

      @include breakpoint(small only) {
        margin: 0;
        padding: 10px 0 0;
      }

      h1 {
        em {
          color: $header-small-font-color;
          font-size: $small-font-size;
        }
      }
    }
  }

  &-footer {
    .inner {
      margin: 0 20px 20px;
      padding: 0;

      p {
        color: #5e5e5e;
        font-size: rem-calc(11);
        line-height: 14px;
        margin-bottom: 10px;
      }
    }
  }
}

.t-comments {
  @include grid-column-margin;

  margin-top: 1rem;
  margin-left: 10px;
  margin-right: 10px;

  h3 {
    color: $summer-sky;
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      font-size: rem-calc(13);
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        color: get-color(alert);
        font-size: rem-calc(11);
        margin-left: 5px;
      }
    }
  }
}

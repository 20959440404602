.radio-obj {
  h3 {
    span {
      color: get-color(alert);
      float: right;
      font-weight: $global-weight-bold;
      margin-left: 8px;
    }
  }

  .info {
    font-size: rem-calc(13);

    a {
      margin-right: $global-margin;
    }

    i {
      margin-left: 0;
    }
  }
}

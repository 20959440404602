.job-list {
  margin: 0 15px;
  padding-top: 15px;

  @include breakpoint(small only) {
    margin: 0 10px;
    padding-top: 10px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid #d9dfe4;
      margin-bottom: 15px;
      min-height: 45px;
      padding-bottom: 15px;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

$facebook_color: #4267b2;
$instagram_color: #f77737;
$telegram_color: #08c;
$vk_color: #2787f5;
$odnoklassniki_color: #faab62;
$yandexzen_color: #f00;

.subscribe-tizer {
  background-color: #edf2f5;
  margin: 15px;
  padding: $global-padding;

  @media print {
    display: none !important;
  }

  .media-object {
    align-items: center;
    margin-bottom: 0;
  }

  .logo {
    background-repeat: no-repeat;
    display: inline-block;
    height: 51px;
    margin-right: 1px;
    text-align: center;
    vertical-align: middle;
    width: 51px;
  }

  .description {
    @include breakpoint(small only) {
      text-align: center;
    }

    span {
      font-size: rem-calc(13);
    }
  }

  .action {
    @include breakpoint(small only) {
      margin-top: $global-margin;
    }
  }

  .button {
    @include color-mixin($white);

    font-size: rem-calc(15);
    margin: 0;
    outline: none;
    text-decoration: none;
  }

  &.facebook {
    background-color: transparentize($facebook_color, 0.85);

    .logo {
      background-image: url("facebook.svg");
    }

    .button {
      background-color: $facebook_color;

      &:hover {
        background-color: darken($facebook_color, 10%);
      }
    }
  }

  &.instagram {
    background-color: transparentize($instagram_color, 0.85);

    .logo {
      background-image: url("instagram.svg");
    }

    .button {
      background-color: $instagram_color;

      &:hover {
        background-color: darken($instagram_color, 10%);
      }
    }
  }

  &.telegram {
    background-color: transparentize($telegram_color, 0.85);

    .logo {
      background-image: url("telegram.svg");
    }

    .button {
      background-color: $telegram_color;

      &:hover {
        background-color: darken($telegram_color, 10%);
      }
    }
  }

  &.vk {
    background-color: transparentize($vk_color, 0.85);

    .logo {
      background-image: url("vk.svg");
    }

    .button {
      background-color: $vk_color;

      &:hover {
        background-color: darken($vk_color, 10%);
      }
    }
  }

  &.ok {
    background-color: transparentize($odnoklassniki_color, 0.85);

    .logo {
      background-image: url("ok.svg");
    }

    .button {
      background-color: $odnoklassniki_color;

      &:hover {
        background-color: darken($odnoklassniki_color, 10%);
      }
    }
  }
}

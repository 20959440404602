footer {
  background-color: $body-background;
  border-top: solid 4px #c5e4f1;
  padding: 20px 10px;

  .copyright {
    color: #2a2a2a;
    font-size: rem-calc(12);
    line-height: 15px;

    p {
      margin: 0 0 5px;
    }

    .phone {
      white-space: nowrap;
    }
  }

  .logo-footer {
    @include show-for(medium);

    float: right;

    svg {
      margin: 0 10px 50px 50px;
      opacity: 0.5;

      &:hover {
        @include mui-animation(wiggle);

        animation-duration: 1500ms;
        animation-iteration-count: 3;
        opacity: 1;
      }
    }
  }

  .counters {
    margin-top: 10px;

    img {
      height: 31px;
      width: 88px;

      & + img {
        margin-left: 10px;
      }
    }
  }
}

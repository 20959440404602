@use "sass:math";
@import "footer";
@import "header";
@import "billboard";
@import "sections";
@import "content";
@import "images";
@import "table";

html {
  height: 100%;
}

body {
  background: $body-background url("bg-body.png") center top repeat-x;
  position: relative;
  z-index: 4;
}

.full-container {
  margin: 0 auto;
  position: relative;

  @include breakpoint(medium) {
    width: $global-width;
  }

  .site-background-with-header & {
    @include breakpoint(medium) {
      padding-top: 150px;
    }
  }
}

.main-container {
  @include grid-row(
    $gutters: (
      small: 0,
      medium: 0,
    )
  );

  background: $white url("bg-main-container.png") top right repeat-y;
  margin: 0 auto;
}

.main-content {
  @include breakpoint(small only) {
    background-color: $white;
  }

  @include breakpoint(medium) {
    @include grid-column(
      math.div(990 - 260, 990),
      (
        small: 0,
        medium: 0,
      )
    );
  }
}

.sidebar {
  @include grid-column(
    math.div(260, 990),
    (
      small: 0,
      medium: 0,
    )
  );
  @include show-for(medium);

  padding: 10px;
}

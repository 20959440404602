.company-obj {
  @include breakpoint(small only) {
    margin: 10px 0;
  }

  &__photo {
    background-image: url("default-photo-company.png");
    border-radius: $global-radius;
    float: left;
    height: 70px;
    overflow: hidden;
    width: 70px;

    img {
      aspect-ratio: 3/2;
      object-fit: fill;
      height: 70px;
      width: 70px;
    }
  }

  &__desc {
    font-size: rem-calc(13);

    h3 {
      font-size: rem-calc(16);
    }
  }
}

@import "company";
@import "company-list";
@import "company-obj";
@import "spravka";
@import "t-company-photo";
@import "t-qrvcard";

h3.h3 {
  font-size: rem-calc(14);
  font-weight: $global-weight-bold;
  margin-top: 10px;
}

.t-gismeteo {
  @include grid-column-margin;

  margin-left: 10px;
  margin-right: 10px;

  h3 {
    border-bottom: 2px solid $summer-sky;
    color: $summer-sky;
    font-size: rem-calc(18);
  }

  iframe {
    border: 0;
  }
}

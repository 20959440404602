.icon {
  display: inline-block;
  height: 16px;
  margin: 0 4px;
  vertical-align: middle;
  width: 16px;

  &-humburger {
    @include hamburger($color: #226791, $color-hover: #226791);

    vertical-align: top;
  }

  &-search {
    background-image: url("icon-search-inline.svg");
    background-repeat: no-repeat;
    display: inline-block;
    height: 18px;
    vertical-align: top;
    width: 18px;
  }

  &-back {
    background-image: url("icon-back-inline.svg");
    background-repeat: no-repeat;
    display: inline-block;
    height: 21px;
    margin-bottom: 3px;
    margin-left: -5px;
    width: 21px;
  }

  &-register {
    background: transparent url("icon-register-inline.svg") center center no-repeat;
  }

  &-login {
    background: transparent url("icon-login-inline.svg") center center no-repeat;
  }

  &-logout {
    background: transparent url("icon-logout-inline.svg") center center no-repeat;
  }

  &-admin {
    background: transparent url("icon-admin-inline.svg") center center no-repeat;
  }

  &-unlock {
    background: transparent url("icon-unlock-inline.svg") center center no-repeat;
  }

  &-settings {
    background: transparent url("icon-settings-inline.svg") center center no-repeat;
  }

  &-comment {
    background: transparent url("icon-comment-inline.svg") center center no-repeat;

    &:hover {
      background: transparent url("icon-comment-hover-inline.svg") center center no-repeat;
    }
  }

  &-hits {
    background: transparent url("icon-hits-inline.svg") center center no-repeat;

    &:hover {
      background: transparent url("icon-hits-hover-inline.svg") center center no-repeat;
    }
  }

  &-download {
    background: transparent url("icon-download-inline.svg") center center no-repeat;
  }

  &-heart {
    background: transparent url("icon-heart-inline.svg") center center no-repeat;

    &:hover {
      background: transparent url("icon-heart-hover-inline.svg") center center no-repeat;
    }
  }

  &-trash {
    background: transparent url("icon-trash-inline.svg") center center no-repeat;
  }

  &-info {
    background: transparent url("icon-info-inline.svg") center center no-repeat;
  }

  &-yes {
    background: transparent url("icon-yes-inline.svg") center center no-repeat;
  }

  &-no {
    background: transparent url("icon-no-inline.svg") center center no-repeat;
  }

  &-admin-site {
    background: transparent url("icon-open-inline.svg") center center no-repeat;
  }

  &-stars1,
  &-stars2,
  &-stars3,
  &-vip {
    height: 14px;
    position: relative;
    top: -2px;
    width: 30px;

    @include breakpoint(small only) {
      margin-top: 5px;
    }
  }

  &-stars1 {
    background: transparent url("icon-star1-inline.svg") center center no-repeat;
  }

  &-stars2 {
    background: transparent url("icon-star2-inline.svg") center center no-repeat;
  }

  &-stars3 {
    background: transparent url("icon-star3-inline.svg") center center no-repeat;
  }

  &-vip {
    background: transparent url("icon-vip-inline.svg") center center no-repeat;
  }

  &-facebook {
    background: #fff url("icon-facebook-inline.svg") center center no-repeat;
  }

  &-twitter {
    background: #fff url("icon-twitter-inline.svg") center center no-repeat;
  }

  &-vkontakte,
  &-vk-oauth2 {
    background: #fff url("icon-vk-inline.svg") center center no-repeat;
  }

  &-google,
  &-google-oauth2 {
    background: #fff url("icon-google-plus.svg") center center no-repeat;
  }

  &-youtube {
    background: #fff url("icon-youtube-inline.svg") center center no-repeat;
  }

  &-yandex,
  &-yandex-oauth2 {
    background: transparent url("icon-yandex-inline.svg") center center no-repeat;
    width: 17px;
  }

  &-tab-facebook {
    background: transparent url("icon-tab-facebook-inline.svg") center center no-repeat;
    height: 26px;
    margin: 0;
    width: 27px;
  }

  &-tab-twitter {
    background: transparent url("icon-tab-twitter-inline.svg") center center no-repeat;
    height: 26px;
    margin: 0;
    width: 27px;
  }

  &-tab-vk {
    background: transparent url("tab-vk-inline.svg") center center no-repeat;
    height: 26px;
    margin: 0;
    width: 27px;
  }

  &-tab-ok {
    background: transparent url("tab-ok-inline.svg") center center no-repeat;
    height: 26px;
    margin: 0;
    width: 27px;
  }

  &-tab-telegram {
    background: transparent url("tab-telegram-inline.svg") center center no-repeat;
    height: 26px;
    margin: 0;
    width: 27px;
  }

  &-tab-youtube {
    background: transparent url("icon-tab-youtube-inline.svg") center center no-repeat;
    height: 26px;
    margin: 0;
    width: 27px;
  }

  &-tab-instagram {
    background: transparent url("icon-tab-instagram-inline.svg") center center no-repeat;
    height: 26px;
    margin: 0;
    width: 27px;
  }

  &-chevron-right {
    background: transparent url("icon-chevron-right-inline.svg") center center no-repeat;
    height: 14px;
    margin: 0;
    width: 14px;
  }

  &-plus {
    background: transparent url("icon-plus-inline.svg") center center no-repeat;
    height: 14px;
    margin: 0;
    width: 14px;
  }

  &-m {
    &-close {
      background-image: url("icon-m-close-inline.svg");
      background-repeat: no-repeat;
      border: none;
      display: inline-block;
      height: 12px;
      width: 12px;
    }
  }
}

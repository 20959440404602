.facetnav {
  border-bottom: dashed 1px $medium-gray;
  font-size: rem-calc(13);
  line-height: 20px;
  margin: 20px;

  @include breakpoint(small only) {
    margin: 5px 10px;
  }

  &__title {
    float: left;
    margin: 1px 0 4px;
    padding: 0;
    width: 110px;

    @include breakpoint(small only) {
      display: block;
      font-size: rem-calc(14);
      width: 100%;
    }
  }

  li {
    display: inline-block !important;
    margin: 1px 4px 4px 0;
    padding: 0 4px 0 0;

    span,
    em {
      color: $dark-gray;
      font-size: rem-calc(11);
      font-weight: $global-weight-normal;
      padding-left: 2px;
    }
  }
}

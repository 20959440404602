.delivery {
  @include grid-column-margin;

  padding: 0 10px;

  h3 {
    color: $summer-sky;
    font-size: rem-calc(18);
    line-height: 1;
  }

  input {
    margin-bottom: 0;
  }
}

.prehistory {
  @include callout;
  @include clearfix;

  clear: both;

  h2 {
    background-color: unset;
    font-size: rem-calc(16);
    line-height: 1.3;
    margin: 0 !important;
    padding: 0 !important;
  }
}

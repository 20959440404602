.realty-obj {
  &__photo {
    background-image: url("default-photo-realty-big.png");
    float: left;
    height: 90px;
    width: 90px;
  }

  &__desc {
    h3 {
      color: $dark-gray;
      font-size: rem-calc(14);
      font-style: italic;
      line-height: 21px;
      margin: 0 0 8px;

      a {
        font-size: rem-calc(15);
        font-style: normal;
      }
    }

    p {
      color: #4a4c53;
      font-size: rem-calc(13);
      line-height: 15px;
      margin-bottom: 4px;
    }
  }

  .cplinks {
    font-size: rem-calc(11);
    padding: 4px 0;
  }
}

@use "sass:math";

.afisha-obj {
  &__wrapper {
    padding: 20px 15px 0;

    @include breakpoint(small only) {
      padding-left: 0;
      padding-right: 0;
      padding-top: 15px;
    }
  }

  h3 {
    @include breakpoint(small only) {
      font-size: rem-calc(16);
    }

    span {
      color: $primary-color;
      font-size: 90%;
      font-weight: $global-weight-normal;

      &::before {
        content: " – ";
      }
    }
  }

  .badge {
    font-size: rem-calc(13);
    margin-left: $global-padding;
  }

  .media-object {
    margin-bottom: $global-margin;
  }

  &__photo {
    background-image: url("default-poster-afisha.png");
    height: auto;
    width: 130px;

    @include breakpoint(small only) {
      height: auto;
      width: 60px;

      img {
        max-width: 100%;
      }
    }
  }

  &__desc {
    width: 100%;

    h1 {
      color: $primary-color;

      span {
        color: $dark-gray;
        font-size: 80%;
        font-weight: $global-weight-normal;

        &::before {
          content: " – ";
        }
      }
    }

    &-date {
      color: $dark-gray;
      font-size: rem-calc(13);
      margin-bottom: $global-margin;

      @include breakpoint(small only) {
        margin-bottom: 0;
      }

      a {
        color: $dark-gray;

        &:hover {
          color: $primary-color;
        }
      }
    }

    &-time {
      @include grid-row;

      line-height: 21px;

      @include breakpoint(small only) {
        margin-bottom: 10px;
        margin-left: 70px;
      }

      .date {
        @include grid-column(math.div(130 + 7, 700));

        color: get-color(alert);
        font-size: rem-calc(12);
        font-weight: $global-weight-bold;
        text-align: right;

        @include breakpoint(small only) {
          @include grid-column;

          font-weight: $global-weight-normal;
          text-align: left;
        }

        &::after {
          content: ":";
        }
      }

      .hour {
        @include grid-column(math.div(700 - 130 - 7, 700));

        @include breakpoint(small only) {
          @include grid-column;
        }

        display: flex;
        align-items: center;

        .has-tip {
          border-bottom: none;
        }

        em {
          color: #333;
          font-size: rem-calc(18);
          font-weight: $global-weight-bold;
          margin-right: 10px;

          @include breakpoint(small only) {
            font-size: rem-calc(14);
          }
        }

        span {
          color: $dark-gray;
          display: inline-block;
          white-space: nowrap;

          @include breakpoint(small only) {
            font-size: rem-calc(13);
          }
        }
      }
    }

    &-info {
      .afisha-list & {
        @include show-for(medium);
      }

      p {
        img {
          max-width: 100%;
        }
      }
    }
  }

  &__place {
    @include clearfix;

    font-size: rem-calc(13);
    margin-bottom: $global-margin;
    margin-top: $global-margin;

    @include breakpoint(medium) {
      @include grid-row;
      @include grid-column(math.div(700 - 130 - 7, 700));
      @include grid-column-offset(math.div(130 + 7, 700));
    }
  }

  &__poster {
    img {
      display: block;
      margin: 0 auto $global-margin;

      @include breakpoint(small only) {
        width: 100%;
      }
    }
  }
}

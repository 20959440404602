.banner {
  @include grid-column-margin;

  display: block;
  text-align: center;

  img {
    .sidebar & {
      max-width: 240px;
    }
  }

  .social-likes + & {
    margin-top: 2rem;
  }
}

iframe.banner {
  border: none;
  overflow: hidden;
}

.sidebar {
  div.banner {
    margin: 0 10px 15px;
    width: 240px;
  }
}

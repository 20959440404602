.login-padding {
  padding: 0 0 15px 20px;

  @include breakpoint(small only) {
    padding: 0 0 15px;
  }

  p {
    line-height: 30px;
  }
}

.billboard {
  @include show-for(medium);

  background-position: center center;
  background-repeat: no-repeat;
  height: 200px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;

  .sticker {
    background-image: url("billboard-sticker.png");
    height: 129px;
    position: absolute;
    top: 35px;
    width: 650px;

    .title {
      color: $white;
      font-family: "Times New Roman", serif;
      font-size: rem-calc(24);
      font-style: italic;
      font-weight: $global-weight-normal;
      line-height: 28px;
      margin: 32px 210px 0 130px;
      text-align: center;
    }
  }

  &-small {
    @include show-for-only(small);

    img {
      background-color: $white;
      height: auto;
      padding-top: 2px;
      width: 100%;
    }
  }
}

.sections {
  @include clearfix;

  padding: 20px 20px 0;

  @include breakpoint(small only) {
    background-color: #ebf7f5;
    margin-bottom: 10px;
    padding: 10px 0;
  }

  @include breakpoint(medium) {
    background: #eaedf2 url("bg-sections.jpg") top right repeat-y;
    border-bottom: #dadfe5 2px solid;
  }

  .columns {
    @include breakpoint(small only) {
      margin-bottom: 0;
      padding: 0 0 0 10px;
    }
  }

  h2 {
    border-bottom: 2px solid #333;
    font-size: rem-calc(18);
    line-height: 21px;
    margin: 0 0 15px !important;
    padding: 0 0 5px;

    span {
      float: right;
      font-size: rem-calc(14);
      margin-top: 5px;
    }
  }
}

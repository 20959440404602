.button {
  &.single {
    margin-bottom: 0;
  }

  &.grey {
    $button-color: #f1f1f1;

    @include button-style($button-color, auto, auto);

    background-image: linear-gradient(
      to bottom,
      scale-color($button-color, $lightness: 10%),
      scale-color($button-color, $lightness: -10%)
    );

    &:focus,
    &:hover {
      background-image: linear-gradient(
        to bottom,
        scale-color($button-color, $lightness: 20%),
        scale-color($button-color, $lightness: -20%)
      );
    }
  }

  &.light-gray {
    @include button-style($light-gray, auto, auto);
  }

  &.blue {
    $button-color: $primary-color;

    @include button-style($button-color, auto, $white);

    background-image: linear-gradient(
      to bottom,
      scale-color($button-color, $lightness: 12%),
      scale-color($button-color, $lightness: -15%)
    );

    &:focus,
    &:hover {
      background-image: linear-gradient(
        to bottom,
        scale-color($button-color, $lightness: 25%),
        scale-color($button-color, $lightness: -30%)
      );
    }
  }
}

a.button {
  text-decoration: none;
}

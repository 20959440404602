.realty-dl {
  font-size: rem-calc(13);
  margin-bottom: 18px;

  dt {
    clear: left;
    float: left;
    font-weight: $global-weight-normal;
    margin: 0;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 23%;
  }

  dt,
  dd {
    line-height: 21px;
  }

  dd {
    margin-left: 25%;
  }
}

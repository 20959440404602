.menu-1 {
  @include show-for(medium);

  background: #2679ac url("bg-menu.png") left top repeat-x;
  border-radius: $global-radius $global-radius 0 0;
  display: block;
  height: 40px;
  padding: rem-calc(6 6 4);
  width: auto;
  z-index: 101;

  // элемент меню

  & > li {
    height: 34px;
    margin: rem-calc(0 7 0 0);

    &.active > a {
      //background-image: linear-gradient(to bottom, #fcfdff, #ccf0e7);
      background: #ebf7f5 url("bg-menu.png") left 40px repeat-x;
      border-radius: $global-radius;
      color: black;
    }

    &.is-active {
      background-color: #ccf0e7;
      border-radius: $global-radius $global-radius 0 0;

      a {
        border-radius: $global-radius $global-radius 0 0;
        padding-bottom: rem-calc(7);
      }
    }

    & > a {
      color: white;
      font-size: rem-calc(14);
      font-weight: $global-weight-normal;
      padding-left: rem-calc(9) !important;
      padding-right: rem-calc(9) !important;

      &:hover {
        background: #ebf7f5 url("bg-menu.png") left 40px repeat-x;
        border-radius: $global-radius;
        color: black;
      }
    }

    &.has-submenu {
      height: 34px;

      &.is-active > a {
        background: #d5efe6 url("bg-menu.png") right -206px no-repeat;
        border-radius: $global-radius $global-radius 0 0;
        color: black;
      }

      & > a {
        background: #2679ac url("bg-menu.png") right -124px no-repeat;
        color: white;
        padding-right: 20px !important;
      }

      &.active > a {
        background: #d5efe6 url("bg-menu.png") right -206px no-repeat;
        border-radius: $global-radius;
        color: black;
      }
    }
  }

  .submenu-1 {
    padding: 5px 0;

    li {
      height: 30px;

      &.active {
        a {
          background-color: white !important;
          background-image: none;
          border-radius: $global-radius;
          color: black;
        }
      }

      a {
        color: black;
        margin: rem-calc(0 5);
        padding-left: rem-calc(5) !important;
        white-space: nowrap;

        &:hover {
          background-color: white;
          border-radius: $global-radius;
        }
      }
    }
  }
}

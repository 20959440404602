.social-tabs {
  font-size: rem-calc(12);
  margin-right: -35px;
  position: fixed;
  right: 0;
  top: 150px;
  width: 35px;

  .site-background-enabled & {
    display: none;
  }

  &__content {
    left: 0;
    list-style: none !important;
    margin-left: -35px;
    position: absolute;
    top: 0;
    width: 35px;

    li {
      background: $dark-gray;
      border-radius: $global-radius 0 0 $global-radius;
      height: 35px;
      margin: 0 0 3px;
      padding: 4px;
      width: 36px;
    }
  }

  &__vk:hover {
    background-color: #45668e;
  }

  &__ok:hover {
    background-color: #faab62;
  }

  &__telegram:hover {
    background-color: #229ed9;
  }

  &__twitter:hover {
    background-color: #1da1f2;
  }

  &__facebook:hover {
    background-color: #3b5998;
  }

  &__youtube:hover {
    background-color: #cd201f;
  }

  &__instagram:hover {
    background-color: #c13584;
  }
}

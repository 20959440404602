.news-list {
  @include clearfix;
  @include grid-column-margin;

  margin-left: 15px;
  margin-right: 15px;

  @include breakpoint(small only) {
    margin: 0;
  }

  h2 {
    @include small-h2;

    border-bottom: solid 1px $light-gray;
    font-size: rem-calc(24);
    margin-top: 0;

    //margin-bottom: 15px;

    a {
      color: $black;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;

    li {
      @include grid-column-margin;

      @include breakpoint(small only) {
        border-bottom: solid 1px $light-gray;
        margin-bottom: 0;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  &__banner {
    margin: 0 auto $global-margin;

    img {
      margin: 0;
    }

    @include breakpoint(small only) {
      margin: 10px auto;
    }
  }

  &__button_more {
    margin-bottom: $global-margin;
    text-align: center;
    width: 100%;

    &-other {
      border: 1px solid $primary-color;
      border-radius: $global-radius;
      cursor: pointer;
      display: inline-block;
      font-size: rem-calc(16);
      line-height: 1;
      padding-bottom: 7px;
      padding-top: 9px;
      text-align: center;
      width: 350px;

      @include breakpoint(small only) {
        margin-top: 5px;
        width: 100%;
      }

      &:hover {
        border-color: $anchor-color-hover;
        color: $anchor-color-hover;
      }
    }
  }

  &__btn-toolbar {
    @include show-for(medium);

    margin-bottom: 0;
    margin-top: 0;

    .button {
      $button-color: #cc324c;

      @include button-style($button-color, auto, $white);

      background-image: linear-gradient(
        to bottom,
        scale-color($button-color, $lightness: 10%),
        scale-color($button-color, $lightness: -10%)
      );
      font-size: rem-calc(11);
      margin-bottom: 0;

      &:hover,
      &:focus {
        background-image: linear-gradient(
          to bottom,
          scale-color($button-color, $lightness: 20%),
          scale-color($button-color, $lightness: -20%)
        );
      }

      & + .button {
        margin-left: 0.5rem;
      }
    }
  }

  &__tags {
    @include show-for(medium);

    a.button {
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }
}

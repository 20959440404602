.post {
  .inner {
    padding: 15px;

    @include breakpoint(small only) {
      padding: 10px 0;
    }
  }

  .date {
    @include clearfix;

    border-bottom: 1px solid $light-gray;
    color: $dark-gray;
    font-size: rem-calc(12);
    margin-bottom: $global-margin;
    padding-bottom: 0.25rem;

    .ad-type {
      margin-bottom: 0;
    }

    .button {
      margin-bottom: 0;

      &.blue {
        color: $white;
      }
    }

    a {
      color: $dark-gray;

      & + a {
        margin-left: 0.5rem;
      }

      &:hover {
        color: $primary-color;
      }

      &.admin {
        @include show-for-only(small);

        color: get-color(alert);
        margin: 0 5px;
      }
    }
  }

  .author,
  .author_link {
    clear: right;
    font-style: italic;
    text-align: right;
  }

  // заголовок статьи

  h1 {
    color: $primary-color;

    @include breakpoint(small only) {
      color: #323232;
    }
  }

  h2 {
    font-size: rem-calc(16);
    line-height: 1.25;
    margin: 20px 0 13px;
  }

  h3 {
    font-size: rem-calc(14);
    font-weight: $global-weight-bold;
    line-height: 1.5;
    margin: 15px 0 10px;
  }

  p,
  li {
    @include breakpoint(small only) {
      font-size: rem-calc(16);
      line-height: 1.5;
    }
  }

  table {
    @include breakpoint(small only) {
      display: block;
      overflow-x: auto;
      width: 100%;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  blockquote {
    font-size: rem-calc($global-font-size + 1);
    font-style: italic;
    margin-left: $global-margin;
  }

  iframe {
    @include breakpoint(small only) {
      max-width: 100% !important;
    }

    &.iframe__pay {
      height: 356px;
      width: 500px;

      @include breakpoint(small only) {
        height: auto;
        width: 100%;
      }
    }
  }

  audio {
    width: 100%;
  }

  .map {
    @include grid-column-margin;

    height: 350px;
    width: 100%;

    @include breakpoint(small only) {
      height: 280px;
    }
  }
}

.radio-list {
  margin: 20px 15px 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 30px;
      overflow: hidden;
    }
  }
}

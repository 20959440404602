.company {
  &-wrap {
    @include clearfix;

    margin: 0 15px;
    padding: 0 0 20px;

    @include breakpoint(small only) {
      margin: 0;
    }
  }

  &__logo {
    margin: 10px 0 20px;
    text-align: center;

    img {
      max-height: 350px;
    }
  }

  &__info {
    @include grid-column-margin;
  }

  ul.contact-list {
    @include clearfix;
    @include grid-column-margin;

    list-style-type: none;
    margin-left: 0;

    li {
      @include breakpoint(small only) {
        color: #323232;
        display: block;
        font-size: rem-calc(16);
        text-align: left;
        width: 100%;
      }

      @include breakpoint(medium) {
        padding-left: 160px;
      }

      span.title {
        clear: left;
        color: $dark-gray;
        display: inline-block;
        float: left;
        font-weight: $global-weight-normal;
        margin: 0 10px 0 0;
        overflow: hidden;
        padding: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 150px;

        @include breakpoint(small only) {
          display: block;
          font-size: rem-calc(12);
          text-align: left;
          width: 100%;
        }

        @include breakpoint(medium) {
          margin-left: -160px;
        }
      }
    }
  }

  // оформление

  h1 {
    color: $primary-color;
  }

  &-related-info {
    border-bottom: solid 1px $light-gray;
    font-size: rem-calc(20);
  }

  &__map {
    @include grid-column-margin;

    height: 350px;
    width: 100%;

    @include breakpoint(small only) {
      height: 280px;
    }
  }

  &__panorama {
    @include grid-column-margin;
  }

  &__panorama + &__info {
    margin-top: 1em;
  }
}

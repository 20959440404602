.list-user-ads {
  margin: 0 18px 20px 20px;

  @include breakpoint(small only) {
    margin-left: 0;
    margin-right: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid #d9dfe4;
      min-height: 45px;
      padding: 15px 0;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.filter-facets {
  font-size: rem-calc(13);
  line-height: 20px;
  padding: 10px 15px;

  h3 {
    border-bottom: 2px solid $summer-sky;
    color: $summer-sky;
    font-size: rem-calc(18);
    margin-bottom: $global-margin;
  }

  &__block {
    margin-top: $global-margin;

    h4 {
      font-size: rem-calc(14);
      font-weight: $global-weight-bold;
    }

    li {
      &.selected {
        background-color: $light-gray;
        border-radius: $global-radius;
        margin-left: -5px;
        padding-left: 5px;
      }
    }
  }
}

.advert-list {
  margin: 0 0 10px;
  padding: 0;
  position: relative;

  @include breakpoint(medium) {
    padding: 0 12px;
    z-index: 1;
  }

  &.spravka {
    margin-left: 3px;
    margin-right: 3px;
  }

  &__item {
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      @include breakpoint(medium) {
        border-top: 1px solid $dark-gray;
      }

      &:first-child {
        border-top: none;
      }
    }
  }
}

.m-menu {
  background-color: #ebf7f5;
  margin: 5px auto 0;
  max-height: 100%;
  padding: 0;

  &__wrap {
    background-color: #ebf7f5;
  }

  &__icon-close {
    line-height: 1;
    margin: 12px 16px 0 0;
    outline: none !important;
  }

  li {
    box-sizing: border-box;
    display: block;
    font-size: rem-calc(13);
    font-weight: $global-weight-bold;
    height: auto;
    line-height: initial;
    list-style: none;
    margin: 0;
    padding: 0;

    &:hover,
    &.active {
      background-color: #ebf7f5;

      a {
        color: #226791 !important;
        font-weight: $global-weight-bold;
      }
    }

    a {
      border-bottom: 1px solid #ddd;
      color: #323232 !important;
      display: block;
      outline: none;
      padding: 15px 25px;

      span {
        text-transform: uppercase;
      }
    }
  }

  ul {
    margin-left: 3px;
  }
}

.slider-news {
  height: 233px;
  position: relative;
  width: 100%;

  @include breakpoint(small only) {
    height: auto;
  }

  &__wrapper {
    padding: 5px 0 0;
    position: relative;

    @include breakpoint(medium) {
      background: $white url("bg-mp-news-list.png") left top repeat-x;
      padding: 20px 15px;
    }

    h1,
    h2 {
      font-size: rem-calc(24);
      line-height: 28px;
      margin-bottom: 13px;

      @include breakpoint(small only) {
        background-color: #ebf7f5;
        color: #323232;
        font-size: rem-calc(22);
        margin-left: -10px;
        margin-right: -10px;
        padding: 10px;
      }

      a {
        color: $black;
      }
    }
  }

  &__item {
    display: none;

    &:first-child {
      display: inherit;
    }

    a {
      img {
        height: 233px;
        width: 350px;

        @include breakpoint(small only) {
          height: auto;
          margin: 0 auto;
          width: 100%;
        }
      }
    }

    &-content {
      @include breakpoint(medium) {
        height: 200px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 335px;
      }

      @include breakpoint(small only) {
        margin: 5px 10px 0;
      }

      .title {
        font-size: rem-calc(18);
        line-height: 1.2;
        margin: 0 0 0.5rem;
      }

      .date {
        color: $dark-gray;
        font-size: rem-calc(12);
        margin-bottom: 0.5rem;
      }

      .tizer {
        @include show-for(medium);

        color: $black;
        font-size: rem-calc(13);
        line-height: 1.4;
      }
    }
  }

  &__more {
    @include show-for(medium);

    bottom: 20px;
    position: absolute;
    right: 20px;
    z-index: 102;

    a {
      font-size: rem-calc(13);
      line-height: rem-calc(13);
      margin: 0;
      padding: rem-calc(4.5 8);
    }
  }
}

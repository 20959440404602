.t-company-photo {
  background-color: #f1f7ed;
  border-bottom: 2px solid #bebebe;
  border-top: 2px solid #619763;
  margin-bottom: 20px;
  padding: 5px 0;
  text-align: center;

  img {
    border-top: 1px solid #d2d7cf;
    padding: 5px 0;

    &:first-child {
      border: none;
    }
  }
}

@mixin small-h2() {
  @include breakpoint(small only) {
    background-color: #ebf7f5;
    color: #323232;
    font-size: rem-calc(20);
    margin-left: -10px;
    margin-right: -10px;
    padding: 10px;
  }
}

@mixin wrap-block-small() {
  @include breakpoint(small only) {
    margin: 0;
    padding: 15px 0 5px;
  }
}

@mixin small-h() {
  @include breakpoint(small only) {
    display: block;
    font-size: rem-calc(14);
    font-weight: $global-weight-bold;
    height: auto;
    line-height: 1.5;
    margin-bottom: 0;

    a {
      color: #323232;
    }
  }
}

@mixin color-mixin($color) {
  color: $color;

  &:hover,
  &:focus,
  &:visited {
    color: $color;
  }
}

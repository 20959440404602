.realty-list {
  margin: 0 18px 20px 20px;

  @include breakpoint(small only) {
    margin: 0 10px 20px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid #d9dfe4;
      padding: 17px 0;
    }
  }

  &__info {
    color: $dark-gray;
    font-style: italic;
  }
}

.company-list {
  @include grid-column-margin;

  @include breakpoint(medium) {
    padding: 0 10px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    border-bottom: 1px solid #d9dfe4;
    padding: 20px 0;

    @include breakpoint(small only) {
      padding: 0;
    }
  }

  &-single {
    margin-top: 1rem;

    @include breakpoint(medium) {
      margin-left: 5px;
      margin-right: 5px;
    }

    h2 {
      font-size: rem-calc(18);
      margin-bottom: 0;
    }

    li {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

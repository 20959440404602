.mp-media-list {
  @include clearfix;

  @include breakpoint(medium) {
    background: $white url("bg-mp-news-list.png") left top repeat-x;
    padding: 15px;
  }

  .button {
    margin-bottom: 0;
  }

  .title {
    @include small-h2;

    border-bottom: solid 1px $light-gray;
    font-size: rem-calc(24);
    margin-bottom: 10px;

    a {
      color: $black;

      &:hover {
        color: $primary-color;
      }
    }

    img {
      margin-bottom: -3px;
    }
  }

  .media-list {
    .date {
      @include show-for(medium);

      color: $dark-gray;
      font-size: rem-calc(11);

      @include breakpoint(small only) {
        text-align: center;
      }
    }

    .title {
      border-bottom: none;
      font-size: rem-calc(13);

      a {
        color: $primary-color;
        text-decoration: $anchor-text-decoration;
      }
    }

    .photo {
      margin-bottom: 0.5rem;
      width: 100%;

      @include breakpoint(small only) {
        display: block;
        margin: 0 auto 5px;
      }

      &-v {
        background-image: url("../video/default-photo-video.png");
        display: block;
        height: 123px;
        width: 164px;
      }

      &-vb {
        display: block;
        height: auto;
        width: 100%;

        @include breakpoint(small only) {
          margin: 0 auto 5px;
        }

        @include breakpoint(medium) {
          background-image: url("../video/default-photo-video-big.png");
          height: 165px;
          width: 220px;
        }
      }

      .empty {
        background-image: url("default-photo-news-big.png");
        display: block;
        height: 150px;
        width: 220px;
      }
    }
  }
}

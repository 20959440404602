.pager {
  @include clearfix;
  @include grid-column-margin;

  margin-left: 10px;
  margin-right: 10px;

  @include breakpoint(small only) {
    margin-left: 0;
    margin-right: 0;
  }

  ul {
    @include show-for(medium);

    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;

      a {
        background-image: linear-gradient(to bottom, #2b8fbb, #1b5991);
        border-radius: $global-radius;
        color: $white;
        display: block;
        height: 25px;
        line-height: 25px;
        margin: 0 5px 0 0;
        text-align: center;
        width: 25px;

        &:hover {
          background-image: linear-gradient(to bottom, #98cff0, #bfe1f6);
          color: #0d344d;
        }
      }

      &.selected a {
        background-image: linear-gradient(to bottom, #98cff0, #bfe1f6);
        color: #0d344d;
      }
    }
  }

  &__prev,
  &__next {
    display: inline-block;
    font-size: rem-calc(14);
    margin: 0 0 0 10px;
    text-transform: capitalize;

    @include breakpoint(small only) {
      border: 1px solid $primary-color;
      margin: 10px 0;
      padding: 5px 10px;
    }
  }

  &__prev {
    @include breakpoint(small only) {
      float: left;
    }
  }

  &__next {
    @include breakpoint(small only) {
      float: right;
    }
  }

  &__single {
    @include breakpoint(small only) {
      display: block;
      float: none !important;
      text-align: center;
    }
  }
}

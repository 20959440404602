ul.errorlist {
  @include grid-column-margin;

  list-style: none;
  margin-left: 0;

  li {
    color: get-color(alert);
    font-size: rem-calc(12);
  }
}

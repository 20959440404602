.gallery-list {
  @include breakpoint(medium) {
    padding: 15px;
  }

  img {
    aspect-ratio: 1;
    border-radius: $global-radius;
    object-fit: fill;
    width: 100%;

    &:hover {
      @include -motion-ui-defaults;

      transform: scale(1.25);
    }
  }
}

.table {
  border-collapse: collapse;
  color: #5e5e5e;
  font-size: rem-calc(13);
  line-height: 18px;
  text-align: center;
  width: 100%;

  @include breakpoint(small only) {
    display: block;
    overflow-x: auto;
    width: 100%;
  }

  p {
    line-height: 18px;
    margin: 0;
  }

  th {
    font-size: rem-calc(13);
    padding: 6px 15px;
    text-align: center;
  }

  td {
    padding: 7px 5px;
  }
}

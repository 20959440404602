.mp-news-rubrica {
  @include clearfix;
  @include grid-column-margin;

  min-height: 220px;
  padding: 0 15px;

  @include breakpoint(small only) {
    padding: 0;
  }

  h2 {
    @include small-h2;

    border-bottom: solid 1px $light-gray;
    font-size: rem-calc(24);
    margin-bottom: $global-margin;

    a {
      color: $black;

      &:hover {
        border-bottom: 2px solid black;
      }
    }
  }

  .latest {
    float: left;
    margin-bottom: $global-margin;

    .thumb {
      float: left;
      margin: 0;

      @include breakpoint(small only) {
        img {
          padding-right: 1rem;
        }
      }
    }

    .photo {
      float: left;
      margin: 0 15px 0 0;
      width: 220px;

      .empty {
        background-image: url("default-photo-news-big.png");
        display: block;
        height: 150px;
        width: 220px;
      }
    }

    .title {
      font-size: rem-calc(15);
      margin-bottom: 0.5rem;
    }

    .date {
      color: $dark-gray;
      font-size: rem-calc(11);
      margin-bottom: 0.5rem;

      @include breakpoint(small only) {
        color: $dark-gray;
        font-style: italic;
      }

      a {
        color: $dark-gray;
      }
    }

    .tizer {
      @include show-for(medium);

      font-size: rem-calc(12);

      &.nopicture {
        margin-left: 0;
      }
    }
  }
}

.list-1 {
  list-style: none;
  margin: 0;

  li {
    line-height: rem-calc(16);
    margin-bottom: 10px;

    @include breakpoint(small only) {
      font-size: rem-calc(14);

      a {
        line-height: 21px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .button {
      margin-bottom: 0;
    }
  }

  &__date {
    color: $dark-gray;
    display: inline-block;
    font-size: rem-calc(13);

    @include breakpoint(small only) {
      line-height: 21px;
    }
  }
}

.job-obj {
  &__desc {
    &-date {
      color: $dark-gray;
      font-size: rem-calc(11);
      line-height: rem-calc(15);
      margin-bottom: 9px;

      a {
        color: $dark-gray;
      }
    }
  }
}

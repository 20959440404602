.map-container {
  background: $white;

  .map {
    margin: 0 15px;
    padding: 0;
  }

  #citymap {
    height: 600px;
    width: 100%;

    @include breakpoint(small only) {
      height: 350px;
    }
  }
}

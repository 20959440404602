.navigate {
  @include clearfix;
  @include grid-column-margin;

  margin: 0 15px;

  @include breakpoint(small only) {
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
  }

  h3 {
    @include small-h2;

    clear: both;
    font-size: rem-calc(18);
  }

  &__next {
    float: right;
    padding-left: 0.5rem;
    text-align: right;
    width: 50%;

    &-arr {
      float: right;
      font-size: rem-calc(16);
      width: 25px;
    }

    &-link {
      margin-right: 25px;
    }
  }

  &__prev {
    float: left;
    padding-right: 0.5rem;
    width: 50%;

    &-arr {
      float: left;
      font-size: rem-calc(16);
      width: 25px;
    }

    &-link {
      margin-left: 25px;
    }
  }
}

.fancybox {
  @include clearfix;

  float: right;
  margin: 0 0 20px 10px;
  width: 356px;

  @include breakpoint(small only) {
    display: block;
    float: none;
    margin: 0;
    margin-bottom: 10px;
    width: auto;
  }

  img {
    margin-bottom: 0 !important;
    vertical-align: top;

    &.fancybox__small {
      object-fit: cover;
      height: 44px;
      width: 66px;
    }

    &.fancybox__big {
      object-fit: cover;
      height: 233px;
      width: 350px;

      @include breakpoint(small only) {
        display: block;
        height: auto;
        width: 100%;
      }
    }
  }

  a {
    &.preview {
      padding: 0 0 0 5px;

      @include breakpoint(small only) {
        padding: 0;
      }
    }

    &.thumbnail {
      float: left;
      margin: 5px 0 0 5px;
    }
  }

  &-wide {
    float: none;
    margin-left: 0;
    width: 700px;

    a.preview {
      padding: 0;
    }

    div.thumbnails {
      margin-left: -5px;
    }
  }
}

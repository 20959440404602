@use "sass:math";

.t-calendar {
  @include grid-column-margin;

  background: $white;
  font-size: rem-calc(13);
  line-height: 1;
  padding: 10px;

  .sidebar & {
    border: solid 1px #eaecef;
    border-radius: $global-radius;
    margin-left: 10px;
    margin-right: 10px;
  }

  table {
    width: 100%;
  }

  th,
  td {
    padding: 5px 4px;
    width: math.percentage(math.div(1, 7));

    a {
      padding: 5px 4px;
    }
  }

  .monthrow {
    font-size: rem-calc(16);

    th {
      padding-bottom: 10px;
    }
  }

  .weekrow {
    th {
      padding: 10px 3px;

      &.sat,
      &.sun {
        color: get-color(alert);
      }
    }
  }

  .dayrow {
    text-align: right;

    .selected {
      a {
        border: solid 1px $medium-gray;
        padding: 4px 3px;
      }
    }
  }
}

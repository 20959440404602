.television {
  &-wrap {
    margin: 20px 0 0;
  }

  &-obj {
    display: block;
    float: left;
    height: 275px;
    margin: 0 20px 20px;
    width: 314px;

    &__title {
      font-size: rem-calc(18);
      height: 24px;
      line-height: 24px;
      overflow: hidden;
      position: relative;
      text-align: center;
    }

    &__logo {
      margin-top: 10px;
    }
  }
}

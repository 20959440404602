.search-obj {
  &__photo {
    float: left;
    height: 70px;
    overflow: hidden;
    width: 70px;

    &-post {
      background-image: url("../doska/default-photo-ads.png");
    }

    &-property {
      background-image: url("../realty/default-photo-realty.png");
    }

    &-org {
      background-image: url("../spravka/default-photo-company.png");
    }

    &-mobile {
      background-image: url("../auto/default-photo-auto.png");
    }
  }

  &__desc {
    h3 {
      font-size: rem-calc(16);
    }

    p {
      font-size: rem-calc(13);
      margin: 0;
    }

    em {
      font-weight: $global-weight-bold;

      + em {
        margin-left: 0.2rem;
      }
    }

    b + b {
      margin-left: 0.2rem;
    }
  }
}

.afisha-list {
  @include clearfix;

  margin: 0 15px;

  @include breakpoint(small only) {
    margin: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-top: 1px solid #dedede;
      padding: 15px 0;

      @include breakpoint(small only) {
        padding: 10px 0;
      }

      &__banner {
        margin: 0 auto $global-margin;

        img {
          margin: 0;
        }

        @include breakpoint(small only) {
          margin: 10px auto;
        }
      }

      &:first-child {
        border-top: none;
      }
    }
  }
}

.ad-type {
  color: $white;
  float: left;
  font-size: rem-calc(11);
  height: 17px;
  line-height: 17px;
  margin: 0 10px 20px 0;
  text-align: center;
  width: 70px;

  span {
    border-radius: $global-radius;
    display: block;
    height: 17px;
    width: 70px;

    &.prodam {
      background-color: #369;
    }

    &.kuplyu {
      background-color: #3b9227;
    }

    &.usluga {
      background-color: #731f1f;
    }

    &.vacancy {
      background-color: #636;
    }

    &.obmen {
      background-color: #636;
    }
  }
}

.m-navbar {
  background-color: $body-background;
  border-bottom: 1px solid #ddd;
  padding: 10px 15px;
  position: relative;

  &__wrap {
    background-color: $body-background;
    margin-top: 0;
    width: 100%;
  }

  &__logo {
    background-image: url("../layout/logo.svg");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 30px;
    vertical-align: top;
    width: 150px;
  }

  &__icon {
    float: right;

    &-wrap {
      padding: 9px 7px;
    }

    &-empty {
      display: inline-block;
      width: 46px;
    }
  }
}

.navibar {
  @include clearfix;

  background: #e4eff5 url("bg-navibar.png") top left repeat-x;
  color: $primary-color;
  font-size: rem-calc(12);
  line-height: 2;
  padding: 5px 15px;

  a {
    display: inline-block;

    & + a::before,
    & + span::before {
      content: "▸";
      padding: 0 5px;
    }
  }

  .button {
    margin-bottom: 0;
  }

  .cplinks {
    float: right;
    margin-left: 20px;

    a {
      color: get-color(alert);

      & + a::before {
        content: "|";
        padding: 0 5px;
      }

      &.add {
        color: get-color(primary);
      }

      &.edit {
        color: get-color(success);
      }

      &.remove {
        color: get-color(alert);
      }
    }
  }

  .float-right {
    a {
      & + a::before {
        content: "·";
        padding: 0 0.5rem;
      }
    }
  }

  .admin-editor {
    display: inline-block;
    float: right;

    a {
      color: get-color(alert);

      & + a::before {
        content: "|";
        padding: 0 0.5rem;
      }
    }
  }
}

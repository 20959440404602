.t-afisha {
  background: #ebebeb url("bg-t-afisha.jpg") right top no-repeat;
  border-bottom: 1px solid $white;
  margin: 0;
  padding: 20px 10px;

  h3 {
    border-bottom: 2px solid #336;
    color: #336;
    margin-bottom: $global-margin;

    a,
    a:visited {
      color: #336;
    }
  }

  .more {
    font-size: rem-calc(11);

    .button {
      $button-color: #2ebca4;

      @include button-style($button-color, auto, $white);

      background-image: linear-gradient(
        to bottom,
        scale-color($button-color, $lightness: 10%),
        scale-color($button-color, $lightness: -10%)
      );
      margin-bottom: 0;

      &:hover,
      &:focus {
        background-image: linear-gradient(
          to bottom,
          scale-color($button-color, $lightness: 20%),
          scale-color($button-color, $lightness: -20%)
        );
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      @include clearfix;
      @include grid-column-margin;

      border-bottom: 1px dotted #333;

      &:last-child {
        border-bottom: none;
      }

      .seance {
        margin-left: 70px;

        em {
          color: #333;
          float: left;
          font-size: rem-calc(14);
          font-weight: $global-weight-bold;
          margin-right: 0.5rem;
          max-width: 150px;
          overflow-x: hidden;
          text-overflow: ellipsis;

          span {
            white-space: nowrap;
          }
        }
      }

      .photo {
        float: left;
        margin: 0 10px 10px 0;
      }

      h4 {
        color: $summer-sky;
        font-size: rem-calc(14);
        font-weight: bolder;

        a,
        a:visited {
          color: $summer-sky;
        }
      }

      p {
        font-size: rem-calc(13);
        margin-bottom: 0;
      }
    }
  }
}

.advert-obj {
  padding: 10px;
  position: relative;
  width: 100%;

  @include breakpoint(medium) {
    background: $white url("advert-obj-bg.png") left top repeat-x;
    box-shadow: 0 0 4px rgb(0 0 0 / 50%);
  }

  &:hover {
    @include breakpoint(medium) {
      box-shadow: 1px 1px 5px rgb(29 10 15 / 87%);
    }
  }

  &__wrapper {
    border-radius: $global-radius;
    width: 100%;

    @include breakpoint(medium) {
      width: 340px !important;
    }
  }

  &__commerce {
    height: 90px;
    overflow: hidden;
    padding: 0;
    width: 340px;

    @include breakpoint(small only) {
      height: auto;
      text-align: center;
      width: 100%;
    }

    @include breakpoint(medium) {
      box-shadow: 0 0 3px rgb(0 0 0 / 50%);

      &:hover {
        box-shadow: 0 0 4px rgb(29 10 15 / 87%);
      }
    }
  }

  // блок фото-тип объявления

  &__media {
    position: relative;

    &-photo {
      background-image: url("../doska/default-photo-ads.png");
      height: 70px;
      overflow: hidden;
      width: 70px;
    }

    &-type {
      color: $white;
      font-size: rem-calc(11);
      height: 17px;
      line-height: 17px;
      position: absolute;
      text-align: center;
      top: 54px;
      width: 70px;

      span {
        border-radius: $global-radius;
        display: block;
        height: 17px;
        width: 70px;

        &.prodam {
          background-color: #369;
        }

        &.kuplyu {
          background-color: #3b9227;
        }

        &.usluga {
          background-color: #731f1f;
        }

        &.vacancy {
          background-color: #636;
        }

        &.obmen {
          background-color: #636;
        }
      }
    }
  }

  // заголовок объявления

  &__title {
    height: 16px;
    overflow: hidden;

    h3 {
      font-size: rem-calc(13);
      line-height: 14px;
      margin: 0;
    }

    .blur {
      background: transparent url("advert-obj-title-blur.png") top right no-repeat;
      height: 16px;
      position: absolute;
      right: 10px;
      width: 40px;
    }
  }

  // описание

  &__desc {
    font-size: rem-calc(12);
    height: 53px;
    line-height: 14px;
    overflow: hidden;

    .blur {
      background: transparent url("advert-obj-desc-blur.png") top right no-repeat;
      bottom: 10px;
      height: 14px;
      position: absolute;
      right: 10px;
      width: 100px;
    }
  }
}

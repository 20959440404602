.related-links {
  @include clearfix;
  @include grid-column-margin;
  @include wrap-block-small;

  margin: 0 15px;

  h3 {
    font-size: rem-calc(18);
    line-height: 21px;
    margin: 0 0 9px;

    @include small-h2;
  }

  ul {
    list-style: disc !important;
    margin: 15px 0 15px 25px;

    @include breakpoint(small only) {
      list-style: none !important;
      margin: 15px 0;
    }
  }
}

header {
  height: 80px;
  margin: 0 auto;
  position: relative;

  .site-background-enabled & {
    background: $body-background url("bg-body.png") center top repeat-x;
  }

  .logo {
    bottom: 10px;
    display: inline-block;
    height: 60px;
    left: 10px;
    position: absolute;
  }

  .age {
    background: #456798;
    border-radius: 20px;
    bottom: 10px;
    color: $white;
    cursor: default;
    font-size: rem-calc(12);
    left: 280px;
    opacity: 0.75;
    padding: 4px;
    position: absolute;

    &:hover {
      opacity: 1;
    }
  }

  .user-tools {
    position: absolute;
    right: 10px;
    top: 10px;

    a {
      margin-left: 0.5rem;
    }

    .avatar {
      border-radius: $global-radius;
      margin-left: 0.5rem;
      margin-right: 0.25rem;
    }
  }

  .searchform {
    bottom: 10px;
    position: absolute;
    right: 10px;

    input {
      background: $white url("icon-search-inline.svg") left center no-repeat;
      border: 1px solid #7db8d7;
      border-radius: $global-radius;
      display: inline-block;
      font-size: rem-calc(13);
      height: 26px;
      line-height: 24px;
      margin: 0;
      padding: 0 5px 0 25px;
      width: 300px;

      &:focus {
        border: 1px solid #7db8d7;
        box-shadow: 0 0 5px #7db8d7;
        width: 500px;
      }
    }

    button {
      font-size: rem-calc(12);
      margin: 0;
      padding: 6px 8px;
    }
  }
}

.video-obj {
  @include breakpoint(small only) {
    margin-bottom: 10px !important;
    margin-top: 5px !important;
  }

  &__date {
    color: $dark-gray;
    font-size: rem-calc(11);
    line-height: 16px;

    @include breakpoint(small only) {
      margin-bottom: 2px;
      margin-left: 2px;
    }

    a {
      color: $dark-gray;
    }
  }

  &__photo {
    display: block;
    height: auto;
    margin-bottom: 5px;
    width: 100%;

    @include breakpoint(medium) {
      background-image: url("default-photo-video-big.png");
      height: 165px;
      width: 220px;
    }
  }

  &__title {
    font-size: rem-calc(13);
    height: 42px;
    line-height: 14px;
    overflow: hidden;
    position: relative;

    @include breakpoint(small only) {
      font-size: rem-calc(15);
      height: auto;
      line-height: 18px;
    }
  }
}

.user-video-obj {
  @include breakpoint(small only) {
    border: 1px solid #ddd;
    margin-bottom: 7px;
    padding: 13px;
  }

  &__photo {
    background-image: url("default-photo-video.png");
    height: 123px;
    width: 165px;
  }

  &__desc {
    font-size: rem-calc(12);
    line-height: 18px;
    margin-bottom: 3px;

    h3 {
      font-size: rem-calc(14);
      line-height: 18px;
      margin: 0 0 5px;
    }

    &-date {
      color: $dark-gray;
      font-size: rem-calc(11);
      line-height: rem-calc(15);
      margin-bottom: 5px;
    }
  }
}

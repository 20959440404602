.t-qrvcard {
  @include grid-column-margin;

  h3 {
    margin: 0 10px 10px;
  }

  svg {
    display: block;
    margin: 0 auto;
  }
}

.comments {
  @include clearfix;
  @include grid-column-margin;

  .inner {
    @include wrap-block-small;

    margin: 0 $global-margin;
    padding: $global-padding 0 0;

    @include breakpoint(medium) {
      border-top: 1px dotted $medium-gray;
    }
  }

  &__item {
    .remove,
    .reply {
      display: none;
      float: right;
      margin-left: 4px;

      .button {
        font-size: rem-calc(10);
        margin-bottom: 0;
      }
    }

    &:hover {
      .remove,
      .reply {
        display: block;
      }
    }
  }

  &__post {
    @include grid-column-margin;

    padding-left: 60px;

    h6 {
      font-size: rem-calc(13);
      font-weight: $global-weight-bold;
      margin: 0 0 5px;
    }

    .avatar {
      float: left;
      margin-left: -60px;
      margin-top: 0;
    }

    textarea {
      height: 52px;
      margin-bottom: 10px;
      padding: 5px;
      resize: none;
      width: 99%;
    }
  }

  &__user {
    height: 18px;
    margin-bottom: 5px;

    .avatar {
      float: left;
      margin: -2px 10px 0 0;
    }

    .username {
      font-size: rem-calc(12);
      line-height: 1.5;
    }

    .date {
      color: $dark-gray;
      font-size: rem-calc(12);
    }
  }

  h3 {
    @include small-h2;

    margin: 0 0 20px;

    span {
      color: $dark-gray;
      font-size: 80%;
      margin-left: 5px;
    }
  }

  p {
    @include breakpoint(small only) {
      font-size: rem-calc(13);
      padding-left: 8px;
    }

    &.can-reply {
      cursor: pointer;
    }
  }

  ul {
    @include grid-column-margin;

    list-style: none;
    margin-left: 0;
    padding: 0;

    li {
      @include grid-column-margin;

      min-height: 60px;

      form,
      p {
        padding-left: 60px;
      }

      textarea {
        height: 52px;
        resize: none;
        width: 100%;
      }

      &.deleted {
        height: 20px;
        min-height: 20px;

        p {
          color: $dark-gray !important;
          font-style: italic;
          padding-left: 10px;
        }
      }
    }
  }

  &-count {
    a {
      color: #5e5e5e;
    }
  }
}

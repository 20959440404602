.user-ads-obj {
  position: relative;

  &__photo {
    border-radius: $global-radius;
    float: left;
    height: 70px;
    overflow: hidden;
    position: relative;
    width: 70px;

    .ad-type {
      bottom: 0;
      margin: 0;
      position: absolute;

      span {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        opacity: 0.75;
      }
    }

    &-ads {
      background-image: url("default-photo-ads.png");
    }

    &-auto {
      background-image: url("../auto/default-photo-auto.png");
    }

    &-realty {
      background-image: url("../realty/default-photo-realty.png");
    }
  }

  &__desc {
    h3 {
      font-size: rem-calc(16);
    }

    h4 {
      font-size: rem-calc(14);
    }
  }
}

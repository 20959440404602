.news-obj {
  @include breakpoint(small only) {
    height: auto;
    margin-top: 10px;
    padding-bottom: 10px;
    word-break: break-word;
  }

  h3 {
    font-size: rem-calc(16);
    margin-bottom: 0;

    @include breakpoint(small) {
      @include small-h;
    }
  }

  &__photo {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 400px;
    width: auto;

    img {
      height: 100px;
      width: 150px;

      @include breakpoint(small only) {
        height: 64px;
        width: 96px;
      }
    }

    &-empty {
      background-image: url("default-photo-news.png");
      display: block;
    }
  }

  &__date {
    color: $dark-gray;
    display: inline-block;
    font-size: rem-calc(12);

    @include breakpoint(small only) {
      font-style: italic;
      margin-bottom: 0;
    }
  }

  &__analytics {
    @include show-for(medium);

    color: $dark-gray;
    display: inline-block;
    font-size: rem-calc(12);
    margin-left: 0.5rem;

    a {
      color: $dark-gray;
    }

    @include breakpoint(small only) {
      color: $dark-gray;
      font-size: rem-calc(12);
      font-style: italic;
      font-weight: $global-weight-bold;

      a {
        color: $primary-color;
      }
    }
  }

  &__desc {
    @include show-for(medium);

    font-size: rem-calc(13);
    line-height: 1.4;

    @include breakpoint(medium) {
      font-size: rem-calc(12);
    }
  }

  &.first-child {
    h3 {
      font-size: rem-calc(18);
    }

    img {
      height: 233px;
      width: 350px;

      @include breakpoint(small only) {
        height: auto;
        width: 100%;
      }
    }
  }
}

.back-top {
  background: url("back-top.png") center center no-repeat;
  bottom: 10px;
  cursor: pointer;
  display: none;
  height: 30px;
  position: fixed;
  right: 7%;
  width: 30px;
  z-index: 1001;
}

@use "sass:math";

.spravka {
  &__row {
    @include grid-row;
    @include grid-column-margin;

    @include breakpoint(medium) {
      background: transparent url("bg-abc.png") -3px top repeat-y;
    }
  }

  &__abc {
    @include grid-column(math.div(40, 730));
    @include show-for(medium);

    min-height: 700px;

    a {
      color: $white;
      display: block;
      font-size: rem-calc(11);
      height: 24px;
      line-height: 24px;
      width: 100%;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      border-bottom: 1px solid #4c8db5;
      height: 24px;
      text-align: center;

      &:hover,
      &.selected {
        background: $white;
        border-radius: $global-radius;

        a {
          color: #4c8db5;
        }
      }

      &.disabled {
        a {
          background: inherit !important;
          color: #4c8db5;
        }
      }
    }
  }

  &__list {
    @include breakpoint(medium) {
      @include grid-column(math.div(690, 730));
    }
  }
}

.l-search {
  margin: 10px 15px 20px;

  @include breakpoint(small only) {
    margin-left: 0;
    margin-right: 0;
  }

  .form-search {
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    margin: 20px 0 10px;
    padding: 0;

    li {
      border-bottom: 1px solid #d9dfe4;
      margin-bottom: 15px;
      min-height: 45px;
      padding-bottom: 15px;
    }
  }

  input {
    display: inline-block;
  }
}

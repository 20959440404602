.auto-obj {
  position: relative;

  &__photo {
    background-image: url("default-photo-auto-big.png");
    float: left;
    height: 90px;
    width: 90px;
  }

  &__title {
    margin-bottom: 6px;
    overflow: hidden;

    @include breakpoint(medium) {
      white-space: nowrap;
    }

    h3 {
      margin: 0;

      a {
        font-size: rem-calc(16);
        line-height: rem-calc(20);
      }
    }
  }

  &__desc {
    font-size: rem-calc(13);
    overflow: hidden;

    &-price {
      color: #414141;

      @include breakpoint(medium) {
        position: absolute;
        right: 15px;
        top: 0;
      }

      @include breakpoint(small only) {
        display: block;
      }
    }
  }
}

.list-2 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    color: #242424;

    .active a {
      font-weight: $global-weight-bold;
    }

    em,
    span {
      color: $dark-gray;
      font-size: 80%;
    }
  }
}

.slick-dots {
  bottom: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  width: 335px;
  z-index: 99;

  li {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    list-style: none;
    padding: 0;

    button {
      background-color: #f6f6f6;
      border-radius: $global-radius;
      cursor: pointer;
      display: block;
      font-size: 0;
      height: 25px;
      line-height: 0;
      margin-right: 5px;
      outline: none !important;
      text-decoration: none;
      width: 25px;
      z-index: 99;

      &:hover {
        background-image: linear-gradient(to bottom, #98cff0, #bfe1f6);
      }
    }

    &.slick-active button {
      background-image: linear-gradient(to bottom, #2b8fbb, #1b5991);
    }
  }
}

@include breakpoint(small only) {
  .submenu-1 {
    margin: 0 !important;

    li {
      border-bottom: 1px dotted $dark-gray;
      display: block;
      font-size: rem-calc(14);
      margin: 0 10px !important;

      a {
        display: block;
        padding: 0.7rem 0.2rem;
        text-decoration: none !important;
      }

      &.active a {
        background-color: #ebf7f5;
        color: $primary-color;
      }
    }
  }
}
